<template>
  <div>
    <div class="d-flex align-items-center">
      <div class="mr-3">
        <a-input @change="searchPegawai" size="large" placeholder="Cari pegawai..." />
      </div>
      <div class="mr-3">
        <a-select size="large" class="w-100" @change="onSelectChange" v-model="selectMonth">
          <template v-for="(item) in months">
            <a-select-option v-if="selectYear === item.tahun" :key="item.id" :value="item.id">
              {{item.bulan}}
            </a-select-option>
          </template>
        </a-select>
      </div>
      <div class="mr-3">
        <a-select size="large" class="w-100" @change="onSelectChange('tahun')" v-model="selectYear">
          <a-select-option v-for="(item, index) in years" :key="index" :value="item">
            {{item}}
          </a-select-option>
        </a-select>
      </div>
      <div class="mr-3">
        <a-select
          size="large"
          v-model="selectUnit"
          @change="onSelectChange"
        >
          <a-select-option key="all">Semua Unit</a-select-option>
          <a-select-option v-for="unit in units" :key="unit.id" :value="unit.id">{{unit.nama}}</a-select-option>
        </a-select>
      </div>
      <!-- <div class="mr-3">
        <a-button @click.prevent="toggleModalExport" type="primary" size="large">
          <i class="far fa-paper-plane"/> Bagikan ke Admin Keuangan
          <a-icon type="dollar" /> Jalankan Payroll
        </a-button>
      </div> -->
      <div class="ml-auto">
        <a-modal
          title="Cetak Rekapitulasi Kehadiran"
          :visible="visibleModalExportRecapitulation"
          @cancel="toggleModalExport"
          centered

        >
        <a-form>
          <a-form-item label="Unit">
            <a-select
              size="large"
              v-model="selectUnit"
            >
              <a-select-option key="all">Semua Unit</a-select-option>
              <a-select-option v-for="unit in units" :key="unit.id" :value="unit.id">{{unit.nama}}</a-select-option>
            </a-select>
          </a-form-item>
        <a-form-item label="Period Data">
          <a-select
            size="large"
            v-model="periodData"
            @change="periodOnChange"
          >
            <a-select-option key="This Month">This Month</a-select-option>
            <a-select-option key="Last Month">Last Month</a-select-option>
            <a-select-option key="3Month">Last 3 Month</a-select-option>
            <a-select-option key="Period">By Period</a-select-option>
            <a-select-option key="Date">By Date</a-select-option>
          </a-select>
        </a-form-item>
        <div v-if="periodData === 'Period'" class="row">
            <div class="col-lg-6">
              <a-form-item label="Month">
                <a-select
                  size="large"
                  default-value="January"
                >
                  <a-select-option key="January">January</a-select-option>
                  <a-select-option key="February">February</a-select-option>
                  <a-select-option key="March">March</a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <div class="col-lg-6">
              <a-form-item label="Year">
                <a-select
                  size="large"
                  default-value="2021"
                >
                  <a-select-option key="2019">2019</a-select-option>
                  <a-select-option key="2020">2020</a-select-option>
                  <a-select-option key="2021">2021</a-select-option>
                </a-select>
              </a-form-item>
            </div>
            </div>
            <div v-if="periodData === 'Date'" class="row">
            <div class="col-lg-6">
              <a-form-item label="Start at">
                <a-date-picker
                  v-model="startAt"
                  :disabled-date="disabledStartDate"
                  show-time
                  format="YYYY-MM-DD"
                  @openChange="handleStartOpenChange"
                  style="width: 100%"
                  size="large"
                />
              </a-form-item>
            </div>
            <div class="col-lg-6">
              <a-form-item label="End at">
                <a-date-picker
                  v-model="endAt"
                  :disabled-date="disabledEndDate"
                  show-time
                  format="YYYY-MM-DD"
                  :open="endOpen"
                  @openChange="handleEndOpenChange"
                  style="width: 100%"
                  size="large"
                />
              </a-form-item>
            </div>
          </div>
          <a-alert message="21-03-2021 to 20-04-2021" type="info" show-icon />
        </a-form>
        <template slot="footer">
          <a-button key="back" @click="toggleModalExport">
            Batal
          </a-button>
          <a-button key="submit" type="primary" :loading="confirmLoadingModalAttendance" @click="handleOkModalExport">
            Cetak Sekarang
          </a-button>
        </template>
        </a-modal>
        <a-button @click.prevent="runPayroll" :disabled="periodeNow && periodeNow.status_periode !== 'berjalan'" type="primary" size="large">
          <a-icon type="dollar" /> Jalankan Penggajian
        </a-button>
        <!-- <a-button class="ml-3" @click.prevent="toggleModalExport" type="danger" size="large">
          <a-icon type="export" /> Cetak
        </a-button> -->
        <a-dropdown>
          <a-menu slot="overlay" @click="handleMenuClick">
            <a-menu-item key="print"> <a-icon type="export" /> Cetak </a-menu-item>
            <a-menu-item key="sync"> <a-icon type="sync" /> Sinkronkan </a-menu-item>
          </a-menu>
          <a-button style="margin-left: 8px" size="large"> Lainnya <a-icon type="down" /> </a-button>
        </a-dropdown>
      </div>
    </div>
    <div class="mt-4">
      <hr>
      <template v-if="periodData !== 'Februari'">
        <a-collapse v-model="activeKey">
          <a-collapse-panel key="1" header="Bantuan">
            <div>
              <div class="d-flex flex-column justify-content-start">
                <p class="font-weight-bold">Keterangan</p>
                <ul class="list-column mt-2">
                  <li><p class="mr-4">HKN: Hari Kerja Normal</p></li>
                  <li><p class="mr-4">JHK: Jumlah Hari Kerja</p></li>
                  <li><p class="mr-4">THK: Tambahan Hari Kerja</p></li>
                  <li><p class="mr-4">S: Sakit</p></li>
                  <li><p class="mr-4">S: Sakit</p></li>
                  <li><p class="mr-4">I: Izin</p></li>
                  <li><p class="mr-4">A: Alfa</p></li>
                  <li><p class="mr-4">C: Cuti</p></li>
                  <li><p class="mr-4">L: Libur</p></li>
                  <li><p class="mr-4">T: Terlambat</p></li>
                  <li><p class="mr-4">PLA: pulang Lebih Awal</p></li>
                </ul>
              </div>
            </div>
          </a-collapse-panel>
        </a-collapse>
        <a-table
          :columns="recapitulationColumns"
          :data-source="recapitulationDataTable"
          bordered
          :loading="loadingTable"
          class="mt-4"
          @change="handleTableChange"
          :pagination="pagination"
        >
          <div slot="nama" slot-scope="value" class="d-flex align-items-center">
            {{value}}
          </div>
          <div slot="nullChecker" slot-scope="value">
            {{value || '-'}}
          </div>
          <div slot="aksi" slot-scope="text, record">
            <a-button @click.prevent="toDetailKehadiranHarianPegawai(record)" size="large"><a-icon type="solution" />Detail</a-button>
          </div>
        </a-table>
      </template>
      <template v-else>
        <div class="d-flex justify-content-center">
          <a-result status="404" title="Belum Ada Rekapitulasi Kehadiran" sub-title="Jika ingin melihat rekapitulasi kehadiran, anda bisa membuat sekarang juga">
            <template #extra>
              <a-button type="primary">
                Buat Sekarang
              </a-button>
            </template>
          </a-result>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// import { bulan } from '@/helpers/pertanggalanIndonesia'
// import moment from 'moment'
const recapitulationColumns = [
  {
    title: 'Nama Pegawai',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
    width: 200,
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    width: 120,
    align: 'center',
    scopedSlots: { customRender: 'nullChecker' },
  },
  {
    title: 'HKN',
    dataIndex: 'hari_kerja_normal',
    key: 'HKN',
    align: 'center',
    scopedSlots: { customRender: 'nullChecker' },
  },
  {
    title: 'KETERANGAN',
    key: 'keterangan',
    align: 'center',
    children: [
      {
        title: 'S',
        dataIndex: 'sakit',
        key: 'sakit',
        align: 'center',
      },
      {
        title: 'I',
        dataIndex: 'izin',
        key: 'izin',
        align: 'center',
      },
      {
        title: 'A',
        dataIndex: 'alfa',
        key: 'alfa',
        align: 'center',
      },
      {
        title: 'C',
        dataIndex: 'cuti',
        key: 'cuti',
        align: 'center',
      },
      {
        title: 'L',
        dataIndex: 'libur',
        key: 'libur',
        align: 'center',
      },
      {
        title: 'T',
        dataIndex: 'terlambat',
        key: 'terlambat',
        align: 'center',
      },
      {
        title: 'PLA',
        dataIndex: 'pulang_lebih_awal',
        key: 'pulang_lebih_awal',
        align: 'center',
      },
    ],
  },
  {
    title: 'JHK',
    dataIndex: 'jumlah_hari_kerja',
    key: 'jumlah_hari_kerja',
    align: 'center',
  },
  {
    title: 'THK',
    dataIndex: 'tambahan_hari_kerja',
    key: 'thk',
    align: 'center',
  },
  {
    title: 'JHK + THK',
    dataIndex: 'jthk',
    key: 'jthk',
    align: 'center',
  },
  {
    title: 'Aksi',
    dataIndex: 'aksi',
    key: 'aksi',
    width: 120,
    align: 'center',
    scopedSlots: { customRender: 'aksi' },
  },
]

export default {
  data() {
    return {
      months: [],
      years: [],
      units: [],
      selectMonth: null,
      selectYear: null,
      selectUnit: 'all',
      recapitulationColumns,
      visibleModalExportRecapitulation: false,
      confirmLoadingModalAttendance: false,
      recapitulationDataTable: [],
      periodData: '',
      activeKey: [],
      loadingTable: false,
      pagination: {},
      searchData: null,
      dataDailyAttendance: {
        dataPegawai: {
          namaPegawai: null,
          nirPegawai: null,
          unitPegawai: null,
          jabatanPegawai: null,
        },
      },
      periodeNow: {},
    }
  },
  methods: {
    toDetailKehadiranHarianPegawai(val) {
      // console.log('val :>> ', val)
      this.$router.push({ name: 'Detail Rekap Absen', params: { idPegawai: val.id_pegawai, idPeriode: val.id_periode } })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Detail Rekap Absen'],
      })
    },
    handleMenuClick(e) {
      // console.log(e)
      if (e.key === 'print') return this.toggleModalExport()
      else if (e.key === 'sync') return this.syncData()
    },
    runPayroll() {
      if (this.periodeNow.status_periode !== 'berjalan') {
        return this.$notification.error({
          message: 'Maaf',
          description: 'Proses pengecekan dengan bagain keuangan sudah berjalan, anda tidak dapat menjalankan penggajian kembali.',
        })
      }
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div>
            <div>Apa anda yakin untuk mengirimkan data rekap pada bagian keuangan?</div>
            <small>*Selanjutnya akan dilakukan koordinasi pengecekan terhadap bagian keuangan dan SDM.</small>
          </div>
        ),
        onOk: () => {
          this.loadingTable = true
          this.$store.dispatch('adminHR/FINALIZE_ABSENT_RECAP', { idPeriod: this.selectMonth })
            .then(isSuccess => {
              if (isSuccess) {
                this.$notification.success({
                  message: 'Sukses.',
                  description: 'Berhasil mengubah status periode menjadi pengecekan',
                })
              } else {
                this.$notification.error({
                  message: 'Maaf',
                  description: 'Terjadi kesalahan pada internal server.',
                })
              }
              this.fetchDataTable({ search: this.searchData })
            })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Lanjutkan',
        cancelText: 'Batalkan',
      })
    },
    syncData() {
      this.loadingTable = true
      this.$store.dispatch('absensiKaryawan/SYNC_ABSENT_RECAP', { idPeriod: this.selectMonth })
        .then(_ => {
          this.fetchDataTable({ search: this.searchData })
        })
    },
    searchPegawai(e) {
      // console.log(value)
      this.searchData = e.target.value
      this.fetchDataTable({ search: this.searchData })
    },
    onSelectChange(status) {
      if (status === 'tahun') {
        const dataMonth = this.months.filter(month => month.tahun === this.selectYear)
        this.selectMonth = dataMonth[0].id
      }
      this.fetchDataTable()
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      let order = ''
      pager.current = pagination.current
      this.pagination = pager
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      this.fetchDataTable({
        page: pagination.current,
        order: order,
        search: this.searchData,
      })
    },
    fetchDataTable(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.loadingTable = true
      this.$store.dispatch('adminHR/FETCH_ALL_PRESENCE_RECAP', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy, idPeriod: this.selectMonth, unit: this.selectUnit })
        .then(({ data, total, periode }) => {
          this.loadingTable = false
          const pagination = { ...this.pagination }
          this.pagination.total = total
          pagination.total = total
          this.pagination = pagination
          this.periodeNow = periode
          this.recapitulationDataTable = data.map(el => {
            return {
              key: el.id,
              id: el.id,
              nama: el.nama,
              ...el.rekap_absens[0],
              unit: el.unit ? el.unit.nama : null,
              jthk: el.rekap_absens[0].jumlah_hari_kerja + el.rekap_absens[0].tambahan_hari_kerja,
            }
          })
        })
    },
    toggleModalExport() {
      this.visibleModalExportRecapitulation = !this.visibleModalExportRecapitulation
    },
    periodOnChange(value) {
      console.log('ok')
      this.periodData = value
    },
    handleOkModalExport() {
      console.log('export')
    },
  },
  created() {
    this.loadingTable = true
    this.$store.dispatch('absensiKaryawan/FETCH_PERIODE_KEHADIRAN', { page: 'all' })
      .then(datas => {
        // console.log(datas)
        // this.column
        if (datas.length) {
          this.selectMonth = datas[0].id
          this.selectYear = datas[0].tahun
        }
        datas.forEach(periode => {
          if (!this.years.includes(periode.tahun)) this.years.push(periode.tahun)
        })
        // this.years = dataTahun.map(data => { return data.tahun })
        this.months = datas.map(data => { return { id: data.id, bulan: data.bulan, tahun: data.tahun } })
        return this.$store.dispatch('absensiKaryawan/FETCH_ALL_UNIT')
      })
      .then(datas => {
        console.log(datas)
        this.units = datas.map(data => { return { id: data.id, nama: data.nama } })
        return this.syncData()
      })
  },
  watch: {
  },
}
</script>

<style lang="scss">
.list-column {
  -webkit-columns: 4;
  -moz-columns: 4;
  columns: 4;
  li {
    margin-right: 20px;
  }
}
.modal-daily-attendance{
  .ant-modal-wrap {
    .ant-modal {
      width: 95% !important;
      // height: 95% !important;
    }
  }
}
</style>
